input {
    border: 1px solid black;
}

button {
    background: lightblue;
    border: 1px solid lightgray;

    &:disabled {
        opacity: 0.5;
    }
}

.clickable {
    cursor: pointer;
}

button {
    &:disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.custom-input {
    border-width: 1px !important;
    border-radius: 4px !important;
    min-height: 38px !important;
    min-width: 300px !important;
    padding: 0 10px;
}

.required-asterix {
    color: red;
    font-size: 0.6rem;
    vertical-align: super;
}

$small: 1050px;

.dashboard-column {
    min-height: 70vh;
    height: auto;
    width: calc(33% - 0.5rem);
    @media screen and (max-width: $small) {
        width: 100%;
        margin-bottom: 2rem;
    }
}

.dashboard-column-extra {
    width: calc(67% - 0.5rem);

    @media screen and (max-width: $small) {
        width: 100%;
    }
}

.dashboard-row {
    max-height: calc(40% - 0.5rem);
    @media screen and (max-width: $small) {
        max-height: 100%;
        margin-bottom: 2rem;
    }
}

.dashboard-row-extra {
    height: calc(60% - 0.5rem);

    @media screen and (max-width: $small) {
        height: 100%;
    }
}

.with-loader {
    position: relative;
    background-color: rgba(226, 226, 226, 0.4);
    overflow: hidden;
}

/* The moving element */
.with-loader::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(transparent),
        color-stop(rgba(255, 255, 255, 0.4)),
        to(transparent)
    );

    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);

    /* Adding animation */
    animation: loading 0.8s infinite;
}

/* Loading Animation */
@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}

.custom-skeleton {
    --skeleton-background: #e2e2e2;
}

.ant-table-thead > tr > th {
    background-color: #f3f4f6 !important;
}

.red-label {
    color: rgb(220, 38, 38);
}

.green-label {
    color: rgb(5, 150, 105);
}

.yellow-label {
    color: rgb(251, 191, 36);
}

.anticon {
    vertical-align: 0.1em !important;
}
.child-select * {
    text-transform: uppercase;
}

.anticon-filter {
    color: rgba(0, 0, 0, 0.7) !important;
}

.tooltip::after {
    content: '';
    position: absolute;
    top: 2em;
    left: 0;
    margin-left: -18px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent #1f2937 transparent transparent;
}

.tradePriceBreakdownTable {
    transition: height 150ms ease-in;
    @apply border-t border-b;
}
.tradePriceBreakdown {
    animation: reveal 150ms linear forwards;
}
.variant-years-table * * * * .ant-table-content {
    border: 1px solid #e3e3e3;
}

@keyframes reveal {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
